import React from "react"
import { content_contact } from "content/contact"
import { Col, Row } from "react-bootstrap"
import { EmailLink } from "components/utility"
import { content } from "content/home"
import { ContactForm, Container, Main, Section, SectionHeading } from "components"

const Contact = () => {
	return (
		<>
			<Main>
				<Section>
					<Container>
						<SectionHeading style={{ textAlign: "left" }}>{content_contact.pageTitle}</SectionHeading>

						<Row xs={1} lg={2}>
							<Col className="mb-4">
								<ContactForm />
							</Col>

							<Col className="px-lg-5">
								<h3>Get in touch</h3>
								<p>
									Email us directly at <EmailLink email="contact@venrup.com" />
								</p>

								{content?.locations?.list?.map((item) => (
									<Row className="mb-3 align-items-center">
										<Col xs={3} sm={2}>
											<div data-aos="fade-up" data-aos-duration="1800">
												{item?.icon}
											</div>
										</Col>
										<Col xs={9} sm={10} className="ps-0">
											<div data-aos="fade-up" data-aos-duration="2000">
												<p className="fw-bold mb-0">{item?.title}</p>
												<p className="mb-0">{item?.phone}</p>
												<p className="mb-0">{item?.email}</p>
											</div>
										</Col>
									</Row>
								))}
							</Col>
						</Row>
					</Container>
				</Section>
			</Main>
		</>
	)
}

export default Contact
